import { API } from 'aws-amplify';

function ClientAPI() {
    //~~~ Client ~~~
    async function getClients() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClients';
            const clientResponse = await API.get(apiName, path);
            return clientResponse;
        } catch (error) {
            console.error('Error getting clients: ', error);
        }
    }

    async function getClientNames() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClientNames';
            const clientNameResponse = await API.get(apiName, path);
            return clientNameResponse;
        } catch (error) {
            console.error('Error getting clients: ', error);
        }
    }

    async function getClient(clientId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClient';
            const queryStringParameters = {
                queryStringParameters: { client_id: clientId },
            };
            const clientResponse = await API.get(apiName, path, queryStringParameters);
            return clientResponse;
        } catch (error) {
            console.error('Error getting client: ', error);
        }
    }

    async function getClientListSearchOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientListSearchOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting client list search options: ', error);
        }
    }

    async function saveClient(clientInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveClient';
            const data = {
                body: clientInfo,
            };
            const clientResponse = await API.post(apiName, path, data);
            //console.log('clientResponse: ', clientResponse)
            return clientResponse;
        } catch (error) {
            console.error('[ClientAPI.saveClient] Error saving client: ', error);
            return false;
        }
    }

    async function saveClientNotes(clientInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveClientNotes';
            const data = {
                body: clientInfo,
            };
            const clientResponse = await API.post(apiName, path, data);
            //console.log('clientResponse: ', clientResponse)
            return clientResponse;
        } catch (error) {
            console.error('[ClientAPI.saveClientNotes] Error saving client notes: ', error);
            return false;
        }
    }

    async function getStates() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetStates';
            const response = await API.get(apiName, path);
            //console.log('[getStates] response:\r\n', response);
            return response;
        } catch (error) {
            console.error('error getting States: ', error);
        }
    }

    async function clientListAutocompleteSearch(searchData) {
        try {
            //console.log('The API search data is:', searchData);
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/AutocompleteSearch';
            const data = {
                body: searchData,
            };
            const response = await API.post(apiName, path, data);
            //console.log('clientListAutocompleteSearch response: ', response);
            return response;
        } catch (error) {
            console.error('error performing the autocomplete search: ', error);
        }
    }

    //~~~ ApplicantCohort ~~~
    async function getApplicantCohorts() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetApplicantCohorts';
            const response = await API.get(apiName, path);
            //console.log('[getApplicantCohorts] response:\r\n', response);
            return response;
        } catch (error) {
            console.error('error getting ApplicantCohorts: ', error);
        }
    }

    async function getApplicantCohort(applicantcohortId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetApplicantCohort';
            const queryStringParameters = {
                queryStringParameters: { applicantcohort_id: applicantcohortId },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('error getting ApplicantCohort: ', error);
        }
    }

    async function saveApplicantCohort(applicantcohortInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveApplicantCohort';
            const data = {
                body: applicantcohortInfo,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('error saving ApplicantCohort: ', error);
        }
    }

    //~~~ Service ~~~
    async function getServices() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetServices';
            const response = await API.get(apiName, path);
            //console.log('[getServices] response:\r\n', response);
            return response;
        } catch (error) {
            console.error('error getting services: ', error);
        }
    }

    async function getService(serviceId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetService';
            const queryStringParameters = {
                queryStringParameters: { service_id: serviceId },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('error getting service: ', error);
        }
    }

    async function saveService(serviceInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveService';
            const data = {
                body: serviceInfo,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('error saving service: ', error);
        }
    }

    async function getServiceGroups() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetServiceGroups';
            const response = await API.get(apiName, path);
            //console.log(response);
            return response;
        } catch (error) {
            console.error('error getting service group names: ', error);
        }
    }

    async function getServiceActionValues() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetServiceActionValues';
            const response = await API.get(apiName, path);
            //console.log(response);
            return response;
        } catch (error) {
            console.error('error getting service action values: ', error);
        }
    }

    //~~~ ClientService ~~~
    async function getClientServices(clientId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClientServices';
            const queryStringParameters = {
                queryStringParameters: { client_id: clientId },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('error getting client services: ', error);
        }
    }

    async function getClientService(clientId, serviceId, startDate) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClientService';
            const queryStringParameters = {
                queryStringParameters: {
                    client_id: clientId,
                    service_id: serviceId,
                    start_date: startDate,
                },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('error getting client service: ', error);
        }
    }

    async function saveClientService(clientserviceInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveClientService';
            const data = {
                body: clientserviceInfo,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('error saving client service: ', error);
            return false;
        }
    }

    async function saveNonActiveClientService(clientserviceInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveNonActiveClientService';
            const data = {
                body: clientserviceInfo,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('error saving non-active client service: ', error);
        }
    }

    async function clientListSearch(searchParameters) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/app/search';
            const data = {
                body: searchParameters,
            };
            // console.log('ClientAPI: clientListSearch =', searchParameters);
            let searchResponse = await API.post(apiName, path, data);

            searchResponse = JSON.parse(searchResponse);
            if ('status' in searchResponse && searchResponse['status'] === 'ResultTooLarge') {
                let s3Result = await Storage.get(searchResponse['keyName'], { download: true });
                let s3Body = await s3Result.Body.text();
                return JSON.parse(s3Body);
            } else {
                return searchResponse;
            }
        } catch (error) {
            console.error('Error executing client list search: ', error);
            return false;
        }
    }

    async function clientListGenerateEditingOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GenerateEditingOptions';
            const editOptionsResponse = await API.get(apiName, path);
            return editOptionsResponse;
        } catch (error) {
            console.error('Error getting client list edit options: ', error);
        }
    }

    async function clientListGenerateFilteringOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GenerateFilteringOptions';
            const editOptionsResponse = await API.get(apiName, path);
            return editOptionsResponse;
        } catch (error) {
            console.error('Error getting client list edit options: ', error);
        }
    }

    async function getLedgerRecordsForEntity(entityID, entityType) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetClientLedgerRecordsForEntity';
            const queryStringParameters = {
                queryStringParameters: { entity_id: entityID, entity_type: entityType },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('error getting Ledger Records: ', error);
        }
    }

    async function getApplicantBasicInfo(searchType, kyList) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetApplicantBasicInfo';
            const queryStringParameters = {
                queryStringParameters: { searchType: searchType, kyList: kyList },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting Basic Applicant Info By BEN: ', error);
        }
    }
    //~~~ ClientFundingYear ~~~
    async function getClientFundingYearData(clientId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientFundingYearData';
            const queryStringParameters = {
                queryStringParameters: { client_id: clientId },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting ClientFundingYear data: ', error);
        }
    }

    async function getClientFundingYearEditOptionsData(clientId) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientFundingYearEditOptionsData';
            const queryStringParameters = {
                queryStringParameters: { client_id: clientId },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting ClientFundingYearEditOptions data: ', error);
        }
    }

    async function saveClientFundingYearRowData(rowData) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/SaveClientFundingYearRowData';
            const data = {
                body: rowData,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response)
            return response;
        } catch (error) {
            console.error('[ClientAPI.saveClientFundingYearRowData] Error saving clientFundingYearRowData: ', error);
            return false;
        }
    }

    async function saveClientFundingYearRowNotes(rowData) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/SaveClientFundingYearRowNotes';
            const data = {
                body: rowData,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response)
            return response;
        } catch (error) {
            console.error('[ClientAPI.saveClientFundingYearRowNotes] Error saving clientFundingYearRowNotes: ', error);
            return false;
        }
    }

    async function saveNewClientFundingYearRowData(rowData) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/SaveNewClientFundingYearRowData';
            const data = {
                body: rowData,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response)
            return response;
        } catch (error) {
            console.error('[ClientAPI.saveNewClientFundingYearRowData] Error saving clientFundingYearRowData: ', error);
            return false;
        }
    }

    async function getClientFRNData(search_type, ky, state) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientFRNData';
            const queryStringParameters = {
                queryStringParameters: { search_type: search_type, ky: ky, state: state },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting Client FRN data: ', error);
        }
    }

    async function getClientConsultingFirmsApplicantData(fundingYear, crn) {
        try {
            // force an update
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientConsultingFirmsApplicantData';
            const queryStringParameters = {
                queryStringParameters: { fundingYear: fundingYear, crn: crn },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error("Error getting consulting firm's applicant data: ", error);
        }
    }

    async function getClientECFData(search_type, ky, state, program, global_search = false) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientECFData';
            const queryStringParameters = {
                queryStringParameters: {
                    search_type: search_type,
                    ky: ky,
                    state: state,
                    program: program,
                    global_search: global_search,
                },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting Client ECF data: ', error);
        }
    }

    async function getClientFundingYearDetailsData(search_type, ky, state, funding_year) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientFundingYearDetailsData';
            const queryStringParameters = {
                queryStringParameters: { search_type: search_type, ky: ky, state: state, funding_year: funding_year },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting Funding Year Details data: ', error);
        }
    }

    async function getClientContactData(ky) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientContactData';
            const queryStringParameters = {
                queryStringParameters: { ky: ky },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting client contact data: ', error);
        }
    }

    async function getClientContactDataOriginal(ky) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientContactDataOriginal';
            const queryStringParameters = {
                queryStringParameters: { ky: ky },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting client contact original data: ', error);
        }
    }

    async function clientListSearchForContacts(searchParams) {
        try {
            //console.log('The API search data is:', searchParams);
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/SearchForClientContacts';
            // const queryStringParameters = {
            //     queryStringParameters: searchParams,
            // };
            const queryStringParameters = {
                queryStringParameters: {
                    ben: searchParams.ben || '',
                    contactName: searchParams.contactName || '',
                    schoolLibraryName: searchParams.schoolLibraryName || '',
                    city: searchParams.city || '',
                    state: searchParams.state || '',
                    zip: searchParams.zip || '',
                },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            //console.log('clientListSearchForContacts response: ', response);
            return response;
        } catch (error) {
            console.error('error performing the client contact search: ', error);
        }
    }

    async function getClientFundingYearFormDetailsData(search_type, ky, fy) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetClientFundingYearFormDetailsData';
            const queryStringParameters = {
                queryStringParameters: { search_type: search_type, ky: ky, fy: fy },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting Funding Year Form Details data: ', error);
        }
    }

    async function getFSTDataForGlobalSearch(search_type, ky) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/ClientList/GetFSTDataForGlobalSearch';
            const queryStringParameters = {
                queryStringParameters: { search_type: search_type, ky: ky },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting FST Data for Global Search: ', error);
        }
    }

    return {
        //~~~ Client ~~~
        GetClients: getClients,
        GetClientNames: getClientNames,
        GetClient: getClient,
        GetClientListSearchOptions: getClientListSearchOptions,
        SaveClient: saveClient,
        SaveClientNotes: saveClientNotes,
        GetStates: getStates,
        ClientListAutocompleteSearch: clientListAutocompleteSearch,
        ClientListSearch: clientListSearch,
        ClientListGenerateEditingOptions: clientListGenerateEditingOptions,
        ClientListGenerateFilteringOptions: clientListGenerateFilteringOptions,
        //~~~ ApplicantCohort ~~~
        GetApplicantCohorts: getApplicantCohorts,
        GetApplicantCohort: getApplicantCohort,
        SaveApplicantCohort: saveApplicantCohort,
        //~~~ Service ~~~
        GetServices: getServices,
        GetService: getService,
        SaveService: saveService,
        GetServiceGroups: getServiceGroups,
        GetServiceActionValues: getServiceActionValues,
        //~~~ ClientService ~~~
        GetClientServices: getClientServices,
        GetClientService: getClientService,
        SaveClientService: saveClientService,
        SaveNonActiveClientService: saveNonActiveClientService,
        //~~~ Ledger ~~~
        GetLedgerRecordsForEntity: getLedgerRecordsForEntity,
        //~~~ ApplicantBasicInfo ~~~
        GetApplicantBasicInfo: getApplicantBasicInfo,
        //~~~ ClientFundingYear ~~~
        GetClientFundingYearData: getClientFundingYearData,
        GetClientFundingYearEditOptionsData: getClientFundingYearEditOptionsData,
        SaveClientFundingYearRowData: saveClientFundingYearRowData,
        SaveClientFundingYearRowNotes: saveClientFundingYearRowNotes,
        SaveNewClientFundingYearRowData: saveNewClientFundingYearRowData,
        //~~~ ClientFRNData ~~~
        GetClientFRNData: getClientFRNData,
        GetClientECFData: getClientECFData,
        GetClientFundingYearDetailsData: getClientFundingYearDetailsData,
        GetClientContactData: getClientContactData,
        GetClientContactDataOriginal: getClientContactDataOriginal,
        ClientListSearchForContacts: clientListSearchForContacts,
        GetClientConsultingFirmsApplicantData: getClientConsultingFirmsApplicantData,
        GetClientFundingYearFormDetailsData: getClientFundingYearFormDetailsData,
        GetFSTDataForGlobalSearch: getFSTDataForGlobalSearch,
    };
}

export default ClientAPI;
