import React from 'react';

import ClientContactSearchReportEngine from '../components/ClientContactSearchReportEngine.js';
import ClientAPI from '../api/ClientAPI.js';

export default function ClientContactSearchReportEnginePage() {
    const clientAPI = new ClientAPI();

    return <ClientContactSearchReportEngine clientAPI={clientAPI} />;
}
